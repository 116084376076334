@import url('./../font/stylesheet.css');
input {
  appearance: none;
}
input:focus {
  outline: none;
}
input[type="text"]:not([class*="mantine"]), input[type="password"] {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
  padding-left: 12px;
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #444444;
  font-size: 14px;
}

input[type="text"]::placeholder {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #8F8389;
  font-size: 14px;
}
input[type="text"]:disabled {
  background: #F4F4F4 0% 0% no-repeat padding-box;
}
input[type="file"] {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  height: 72px;
  border: none;
  padding-left: 12px;
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #444444;
  font-size: 32px;
}
/* input[type="checkbox"] {
  width: 18px;
  height: 18px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #18BA95;
  border-radius: 4px;
  opacity: 1;
}
input[type="checkbox"]:checked {
  width: 18px;
  height: 18px;
  background: url(../img/ico-check.svg) center center no-repeat;
  border: 2px solid #18BA95;
  border-radius: 4px;
  opacity: 1;
} */
input[type="radio"] {
  width: 22px;
  height: 22px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
}
input[type="radio"]:checked {
  width: 22px;
  height: 22px;
  background: url(../img/pointer-radio.svg) center center no-repeat;
  border: 2px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
}
input[type="submit"]:disabled {
  width: 200px;
  height: 40px;
  background: #ECEAEB 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  color: #8F8389;
  border: none;
}
input[type="submit"] {
  width: 200px;
  height: 40px;
  background: #18BA95 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  border: none;
}
button {
  width: auto !important;
  /* min-width: 150px; */
  /* height: 40px !important; */
  background: #18BA95 0% 0% no-repeat padding-box;
  border-radius: 24px !important;
  opacity: 1;
  color: #FFFFFF;
  border: none;
}
button.secondary {
  border: 1px solid #18BA95;
  color: #18BA95;
  background: #FFFFFF;
  margin: 0 auto;
}
button.secondary:hover {
  background: #18BA95 0% 0% no-repeat padding-box;
  color: #FFFFFF;
}
textarea {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  height: 75px;
  border: none;
  padding-left: 12px;
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #444444;
  font-size: 14px;
  padding-top: 12px;
}
textarea::placeholder {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #8F8389;
  font-size: 14px;
}
textarea:focus {
  outline: none;
}
select {
  width: 100%;
  height: 40px;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
.dropdown {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #444444;
  text-align: left;
  text-indent: 16px;
  position: relative;
  border-radius: 8px;
  background: #F4F4F4;
  border: none;
  background-image: url(../img/chrn-down.svg);
  background-position: 158px center;
  background-repeat: no-repeat;
}
.dropdown:focus {
  outline: none;
}

.files-dropdown {
  height: 40px;
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #444444;
  font-size: 14px;
  background-position: 95%;

}