@import url('./../font/stylesheet.css');
@import url('./ui-kit.css');

body {
  margin: 0;
  padding: 0;
}

.bg-degree1 {
  background: transparent linear-gradient(119deg, #ECEAEB 0%, #C5BFC2 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.bg-degree2 {
  background: transparent linear-gradient(119deg, #18BA97 0%, #1DD768 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.bg-white {
  background: #FFFFFF;
  opacity: 1;
  width: 100%;
  overflow: hidden;
}

.container-fluid {
  display: block;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: auto;
  overflow: hidden;
  padding-bottom: 24px;
}

.nav-container {
  width: calc(100% - 140px);
  height: 96px;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
}

nav {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
}

nav ul li {
  float: left;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

nav ul li a {
  display: block;
  color: #ffffff;
  padding: 11px 20px;
  /*border-radius: 24px;*/
  text-decoration: none;
  font-family: 'airbnb_cereal_appmedium', sans-serif !important;
  font-size: 14px;
}

nav ul li a:hover {
  border-bottom: 2px solid #FFFFFF;
  color: #FFFFFF;
}

nav ul li a.active {
  border-bottom: 2px solid #FFFFFF;
  color: #FFFFFF;
}

.login-content {
  width: 680px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
}

.block {
  width: 340px;
  overflow: hidden;
  padding: 40px;
  margin-top: 181px;
}

.block.bg-white {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 12px 0px 0px 12px;
  opacity: 1;
}

.block.bg-white .content-int {
  width: 280px;
  overflow: hidden;
  text-align: center;
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  color: #18BA95;
}

.block.bg-white .content-int .btn-prim-index {
  display: block;
  width: 200px;
  height: 40px;
  padding-top: 8px !important;
  background: #18BA95 no-repeat padding-box;
  border: 1px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.block.bg-white .content-int .btn-prim-index:hover {
  background: #F78154 no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #F78154;
}

.block.bg-white .content-int .btn-prim-login {
  display: block;
  width: 200px;
  height: 15px;
  padding-top: 8px !important;
  background: #18BA95 no-repeat padding-box;
  border: 1px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.block.bg-white .content-int .btn-prim-login:hover {
  background: #F78154 no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #F78154;
}

.block.bg-white .content-int .btn-second-download {
  display: block;
  width: 200px;
  height: 40px;
  background: transparent no-repeat padding-box;
  border: 1px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  color: #18BA95;
  padding: 8px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.block.bg-white .content-int .btn-second-download:hover {
  background: #ffffff no-repeat padding-box;
  color: #1CD760;
}

.block.bg-green-degree {
  background: transparent linear-gradient(317deg, #18BA95 0%, #74E68D 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 12px 12px 0px;
  opacity: 1;
}

.title {
  width: 100%;
  text-align: center;
}

.title h1 {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 20px;
}

.form-content {
  width: 100%;
  overflow: hidden;
  display: flex;
}

form {
  width: 100%;
  overflow: hidden;
}

form .input-content {
  width: 99%;
  overflow: hidden;
  display: flexbox;
}

form .input-content span {
  width: 100%;
  overflow: hidden;
  display: block;
  padding-left: 12px;
  font-size: 12px;
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #8F8389;
  margin-top: 4px;
  visibility: hidden;
}

form .input-content.error input {
  border: 1px solid #C33149;
}

form .input-content.error span {
  color: #C33149;
  visibility: visible;
}

form .input-content label {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #8F8389;
  font-size: 12px;
}

form .input-content .forget {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #18BA95;
  font-size: 12px;
  text-decoration: underline;
}

form .input-content .txt-bajada {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  font-size: 14px;
  color: #444444;
  padding-bottom: 16px;
}

form .input-content .secondary-btn {
  background: #FFFFFF;
  color: #18BA95;
  border: 1px solid #18BA95;
}

form .input-content .password-icon {
  background: url(../img/ico-eye-hide.svg);
  width: 24px;
  height: 24px;
  float: right;
  position: absolute;
  cursor: pointer;
  margin: 9px 224px;
}

form .input-content .password-icon-hide {
  background: url(../img/ico-eye-show.svg);
  width: 24px;
  height: 24px;
  float: right;
  position: absolute;
  cursor: pointer;
  margin: 9px 224px;
}

form .input-content .col-content {
  display: block;
  width: 50%;
}

form .input-content .col-content .input-content label {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #8F8389;
  font-size: 14px;
}

form .input-content .radio-content {
  width: 49px;
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  color: #8F8389;
  display: flex;
  align-items: center;
  font-size: 14px;
}

form .input-content .label-files {
  z-index: 1;
  position: absolute;
  cursor: pointer;
  margin: 32px 16px;
  font-size: 14px;
  color: #18BA95;
  text-decoration: underline;
}

form .input-content .area-files {
  width: 609px;
  height: 72px;
  position: absolute;
  margin-top: -68px;
  border-radius: 8px;
  background: #F4F4F4;
}

.mg-bottom {
  margin-bottom: 24px;
}

.files-content {
  width: 957px;
  /* height: 545px; */
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  border-radius: 12px;
  box-shadow: 0px 4px 24px #00000029;
  margin-top: 24px;
}

.files-content .block {
  width: 957px;
  overflow: hidden;
  padding: 40px;
  margin-top: 0px;
}

.files-content .block.bg-white {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 12px 0px 0px 12px;
  opacity: 1;
  width: 699px;
}

.files-content .block.bg-green-degree {
  background: transparent linear-gradient(317deg, #18BA95 0%, #74E68D 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 12px 12px 0px;
  opacity: 1;
  width: 258px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files-content .block .title {
  width: 100%;
  text-align: left;
}

.files-content .block .title h1 {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 20px;
}

.files-content .block #upload {
  opacity: 0;
}

.files-content .block .file-box {
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  height: 72px;
  background: #F4F4F4;
}

.files-content .block .file-box a {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: #18BA95;
}

.message-content {
  width: 957px;
  height: 70vh;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.message-content .content-int {
  width: 320px;
  overflow: hidden;
  text-align: center;
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  color: #fff;
}

.message-content .content-int span:nth-child(3) {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 20px;
}

.message-content .content-int span:nth-child(5) {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 20px;
}

.message-content .content-int span:nth-child(7) {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  font-size: 16px;
}

.message-content .content-int .btn-second-cancel {
  display: block;
  width: 250px;
  background: transparent no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.message-content .content-int .btn-second-cancel:hover {
  background: #ffffff no-repeat padding-box;
  color: #1CD760;
}

.message-content .content-int .btn-prim-cancel {
  display: block;
  width: 200px;
  background: #ffffff no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  opacity: 1;
  color: #1CD760;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.message-content .content-int .btn-prim-cancel:hover {
  background: #F78154 no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #F78154;
}

.message-content .content-int .btns {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.message-download {
  width: 957px;
  height: 70vh;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.message-download .content-int {
  width: 280px;
  overflow: hidden;
  text-align: center;
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  color: #18BA95;
}

.message-download .content-int span:nth-child(3) {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 20px;
}

.message-download .content-int span:nth-child(4) {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  font-size: 16px;
}

.message-download .content-int .btn-second-download {
  display: block;
  width: 200px;
  background: transparent no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.message-download .content-int .btn-second-download:hover {
  background: #ffffff no-repeat padding-box;
  color: #1CD760;
}

.message-download .content-int .btn-prim-download {
  display: block;
  width: 200px;
  height: 40px;
  padding-top: 8px !important;
  background: #18BA95 no-repeat padding-box;
  border: 1px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
}

.message-download .content-int .btn-prim-download:hover {
  background: #F78154 no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #F78154;
}

.message-download .content-int .btns {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.grilla-content {
  width: calc(100% - 140px);
  margin: 0 auto;
}

.grilla-content .title-grilla {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.grilla-content .title-grilla h1 {
  font-size: 20px;
  font-family: 'airbnb_cereal_appbold', sans-serif;
}

.nav-consulta {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-consulta ul {
  list-style: none;
}

.nav-consulta ul li {
  float: left;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.nav-consulta ul li a {
  display: block;
  color: #74E68D;
  padding: 11px 20px;
  text-decoration: none;
  font-family: 'airbnb_cereal_appmedium', sans-serif !important;
  font-size: 16px;
}

.nav-consulta ul li a:hover {
  border-bottom: 2px solid #74E68D;
  color: #74E68D;
}

.nav-consulta ul li a.active {
  border-bottom: 2px solid #74E68D;
  color: #74E68D;
}

.nav-consulta .close-sesion {
  border-radius: 8px 8px 0px 0px;
  background: transparent;
  color: #74E68D;
  border-bottom: none;
  transition: all 0.2s ease-in-out;
  display: flex;
}

.nav-consulta .close-sesion:hover {
  border-bottom: none;
}

.nav-consulta .close-sesion.activado {
  border-radius: 8px 8px 0px 0px;
  background: #74E68D !important;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}

.nav-consulta .close-sesion.activado .ico-user {
  background: url(../img/user-avatar-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.nav-consulta .close-sesion .ico-user {
  background: url(../img/user-avatar-green.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.nav-consulta .cerrar-sesion {
  width: 289px;
  background: #74E68D;
  top: 57px;
  margin-left: 533px;
  border-radius: 0px 0px 8px 8px;
  text-align: center;
  padding: 8px;
  z-index: 100;
  position: absolute;
}

.nav-consulta .cerrar-sesion a {
  font-family: 'airbnb_cereal_appmedium', sans-serif !important;
  color: #FFFFFF;
}

.close-sesion {
  border-radius: 8px 8px 0px 0px;
  background: transparent;
  color: #ffffff;
  border-bottom: none;
  transition: all 0.2s ease-in-out;
  display: flex;
}

.close-sesion:hover {
  border-bottom: none;
}

.close-sesion.activado {
  border-radius: 8px 8px 0px 0px;
  background: #FFFFFF !important;
  color: #18BA95;
  transition: all 0.2s ease-in-out;
}

.close-sesion.activado .ico-user {
  background: url(../img/user-avatar-green.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.close-sesion .ico-user {
  background: url(../img/user-avatar-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.cerrar-sesion {
  width: 263px;
  background: #FFFFFF;
  top: 56px;
  margin-left: 493px;
  border-radius: 0px 0px 8px 8px;
  text-align: center;
  padding: 8px;
  z-index: 100;
  position: absolute;
}

.cerrar-sesion a {
  font-family: 'airbnb_cereal_appmedium', sans-serif !important;
  color: #18BA95;
}

.nav-link.active {
  color: #18BA95 !important;
  border-color: #18BA95 !important;
}

.nav-tabs .nav-link:hover {
  background-color: #ffffff !important;
  border-color: transparent;
  color: #18BA95 !important;
  border-color: #18BA95 !important;
}

.nav-tabs .nav-link {
  border: solid transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  font-size: 14px;
  color: #C5BFC2;
  padding: 17px 29px 16px;
  font-family: 'airbnb_cereal_appmedium', sans-serif !important;
}

.table {
  font-family: 'airbnb_cereal_appbook', sans-serif !important;
  font-size: 14px !important;
}

.table thead {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
}

.table tbody th a {
  color: #18BA95;
  text-decoration: none;
}

.table tbody th a:hover {
  text-decoration: underline;
}

.table tbody tr td .actions {
  /* width: 106px; */
  display: inline-flex;
  justify-content: space-between;
}

.table tbody tr td .actions .ico-action {
  width: 28px;
  height: 29px;
  display: block;
  margin-left: 3px;
}

.table tbody tr td .actions .ico-action .descarga-on {
  background: url(../img/ico-descarga-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .actions .ico-action .reenvio-on {
  background: url(../img/ico-reenvio-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .actions .ico-action .eliminar-on {
  background: url(../img/ico-eliminar-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .actions .ico-action .descarga-off {
  background: url(../img/ico-descarga-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .actions .ico-action .reenvio-off {
  background: url(../img/ico-reenvio-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .actions .ico-action .eliminar-off {
  background: url(../img/ico-eliminar-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.table tbody tr td .estado {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  font-size: 14px;
}

.table tbody tr td .estado.activo {
  color: #1CD760;
}

.table tbody tr td .estado.expirado {
  color: #F78154;
}

.table tbody tr td .estado.eliminado {
  color: #C33149;
}

.paginador {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginador nav {
  display: contents;
}

.paginador nav .pagination .page-item .page-link {
  color: #C5BFC2 !important;
  font-family: 'airbnb_cereal_appbook', sans-serif !important;
  font-size: 14px !important;
}

.paginador nav .pagination .page-item .page-link.activo {
  background: #74E68D;
  color: #FFFFFF !important;
}

.col-details {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  font-size: 14px;
}

.col-details .row {
  margin-bottom: 15px;
  align-items: center;
}

.col-details .row .col:nth-child(1) {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  max-width: 205px !important;
}

.col-details .row .col .actions {
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-details .row .col .actions .ico-action {
  width: 28px;
  height: 29px;
  display: block;
}

.col-details .row .col .actions .ico-action .descarga-on {
  background: url(../img/ico-descarga-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .row .col .actions .ico-action .reenvio-on {
  background: url(../img/ico-reenvio-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .row .col .actions .ico-action .eliminar-on {
  background: url(../img/ico-eliminar-on.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .row .col .actions .ico-action .descarga-off {
  background: url(../img/ico-descarga-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .row .col .actions .ico-action .reenvio-off {
  background: url(../img/ico-reenvio-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .row .col .actions .ico-action .eliminar-off {
  background: url(../img/ico-eliminar-off.svg);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 29px;
}

.col-details .total-download {
  width: 100% !important;
  margin-top: 48px;
}

.col-details .total-download .col-download {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  min-width: 400px !important;
}

.col-details .total-download .col-download span {
  font-size: 42px;
  font-family: 'airbnb_cereal_appbold', sans-serif;
}

.col-details .file-status {
  font-family: 'airbnb_cereal_appmedium', sans-serif;
  color: #18BA95;
}

.mg-bttn4 {
  margin-bottom: 4px;
}

.mg-bttn8 {
  margin-bottom: 8px;
}

.mg-bttn12 {
  margin-bottom: 12px;
}

.mg-bttn16 {
  margin-bottom: 16px;
}

.row .content-int .btn-prim-download {
  display: block;
  width: 140px;
  height: 40px;
  padding-top: 8px !important;
  background: #18BA95 no-repeat padding-box;
  border: 1px solid #18BA95;
  border-radius: 24px;
  opacity: 1;
  color: #FFFFFF;
  padding: 12px;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 16px;
  justify-content: center;
}

.row .content-int .btn-prim-download:hover {
  background: #F78154 no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #F78154;
}

.dropdown-remove-arrow .dropdown-toggle::after {
  display: none;
}

.text-area-observation {
  font-family: 'airbnb_cereal_appbook', sans-serif;
  color: #fa007d;
  font-size: 14px;
}

.message-file-name {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 13px;
  color: #18BA95;
  font-weight: 200;
}

.message-file-size {
  background-color: #18BA95 !important;
}

.highlight {
  background: #8de4a859;
  transition: all 0.5s ease-in-out;
}

.debug {
  border: '1px solid red'
}

.title-user-edit {
  font-family: 'airbnb_cereal_appbold', sans-serif;
  font-size: 16px;
  color: #18BA95;
}

.form-check-input[type=radio]:checked:after {
  background-color: #18BA95;
  border-color: #18BA95;

  border-radius: 50%;
  width: 0.625rem;
  height: 0.625rem;
  transition: border-color;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.form-check-input[type=radio]:checked:before {
  background-color: #18BA95;
  border-color: #18BA95;

  content: "";
  position: absolute;
  box-shadow: 0px 0px 0px 13px transparent;
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}


.form-check-input[type=radio]:checked {
  border-color: #18BA95;
  width: 1.25rem;
  height: 1.25rem;
}

.info-header {
  border: 1px solid #18BA95;
}

.mantine-check input[type="checkbox"] {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  border-radius: none;
  opacity: none;
}

.mantine-select span{
  padding-left: 0px !important;
  color: #000 !important;
  margin-top: 0px !important;
  visibility: visible !important;
}

form .input-content.error .mantine-select input{
  border: none;
}
/* 
form .input-content.error .mantine-select{
  border: 1px solid #1cd200;
} */