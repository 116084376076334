/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 27, 2022 */



@font-face {
    font-family: 'airbnb_cereal_appbold';
    src: url('airbnbcerealbold-webfont.woff2') format('woff2'),
         url('airbnbcerealbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_appbook';
    src: url('airbnbcerealbook-webfont.woff2') format('woff2'),
         url('airbnbcerealbook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_applight';
    src: url('airbnbcereallight-webfont.woff2') format('woff2'),
         url('airbnbcereallight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_appmedium';
    src: url('airbnbcerealmedium-webfont.woff2') format('woff2'),
         url('airbnbcerealmedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}